import LazyImage from '@basicComponents/image';
import React from 'react';
import {View} from 'react-native';
import {copyIcon, defaultHeaderImg, emptyHeaderImg} from './me.variable';
import theme from '@/style';
import {useInnerStyle} from './me.hooks';
import Text from '@basicComponents/text';
import {IUserInfo} from '@/services/global.service';
import dayjs from 'dayjs';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {copyText, goTo} from '@/utils';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {SafeAny} from '@/types';
import {flex} from '@/components/style';
const rightIcon = require('@components/assets/icons/chevron-right.png');

export interface MeUserProps {
  user?: IUserInfo;
  login: boolean;
}
const MeUser: React.FC<MeUserProps> = ({user, login}) => {
  const {i18n} = useTranslation();
  const {
    size: {topInnerHeaderSize},
    userStyle,
  } = useInnerStyle();
  const copy = (content: string) => {
    if (!login) {
      goTo('Login');
      return;
    }
    copyText(content);
    globalStore.globalTotal.next({
      type: 'success',
      message: i18n.t('copy-success'),
    });
  };
  return (
    <NativeTouchableOpacity
      activeOpacity={login ? 1 : 0.8}
      style={[flex.row, flex.between, flex.centerByCol]}
      onPress={(e: SafeAny) => {
        if (!login) {
          goTo('Login');
        } else {
          e.preventDefault();
          goTo('Profile');
        }
      }}>
      <View
        style={[
          theme.flex.row,
          theme.padding.tbs,
          theme.margin.btml,
          theme.flex.centerByCol,
          // eslint-disable-next-line react-native/no-inline-styles
          {flex: 1},
        ]}>
        <View
          style={[
            theme.flex.center,
            userStyle.headerWrap,
            theme.background.white,
          ]}>
          <LazyImage
            width={topInnerHeaderSize}
            height={topInnerHeaderSize}
            imageUrl={
              login
                ? user?.userAvatar
                  ? user.userAvatar
                  : defaultHeaderImg
                : emptyHeaderImg
            }
            occupancy="#0000"
            radius={topInnerHeaderSize / 2}
          />
        </View>
        <View style={[theme.flex.col, theme.padding.leftxxl, {flex: 1}]}>
          <Text
            numberOfLines={1}
            fontSize={18}
            style={[theme.font.white, theme.margin.btmxxs]}
            blod>
            {(user?.userName ? user?.userName : user?.userPhone) ||
              i18n.t('me.user.login')}
          </Text>
          <View style={[theme.flex.row, theme.margin.btms]}>
            <View
              style={[
                theme.padding.lrl,
                theme.padding.tbxxs,
                userStyle.uid,
                theme.flex.center,
                theme.margin.rights,
              ]}>
              <Text fontSize={12} style={[theme.font.white]}>
                {i18n.t('me.user.uid')}
                {user ? user.userId : '******'}
              </Text>
            </View>
            {user ? (
              <NativeTouchableOpacity onPress={() => copy(user.userId + '')}>
                <LazyImage
                  imageUrl={copyIcon}
                  occupancy="#0000"
                  width={theme.iconSize.l}
                  height={theme.iconSize.l}
                />
              </NativeTouchableOpacity>
            ) : null}
          </View>
          <Text fontSize={theme.fontSize.s} style={[theme.font.white]}>
            {i18n.t('me.user.lastLogin')}
            {user
              ? dayjs(user.lastLoginTime).format('DD/MM/YYYY hh:mm:ss A')
              : '-'}
          </Text>
        </View>
      </View>
      <LazyImage
        // style={{marginLeft: 8}}
        occupancy={'transparent'}
        imageUrl={rightIcon}
        width={14}
        height={14}
      />
    </NativeTouchableOpacity>
  );
};

export default MeUser;

import {FadeInView} from '@basicComponents/animations';
import React, {useCallback, useEffect, useState} from 'react';
import Animated, {useSharedValue, withTiming} from 'react-native-reanimated';
import {defaultHeaderImg, emptyHeaderImg, topBackground} from './me.variable';
import theme from '@/style';
import {useInnerStyle} from './me.hooks';
import MeUser from './me-user';
import LazyImage from '@basicComponents/image';
import {
  RefreshControl,
  View,
  StyleSheet,
  Image,
  ScrollView,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from 'react-native';
import MeAmount from './me-amount';
import MeLists from './me-lists';
import globalStore from '@/services/global.state';
import {
  IUserInfo,
  MessageCountInfo,
  postUserInfo,
  postVipConfig,
} from '@/services/global.service';
import LinearGradient from '@basicComponents/linear-gradient';
import {goTo} from '@/utils';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useFocusEffect} from '@react-navigation/native';
import MeVipCard, {VipInfo} from './me-vip-card';
import {postVipInfo} from '@services/global.service';
import Text from '@basicComponents/text';
import {useTranslation} from 'react-i18next';
import MePromotions from './me-promotions';

const Me = () => {
  const {i18n} = useTranslation();
  const {
    size: {
      screenWidth,
      screenHeight,
      topBgHeight,
      startTopUserHeight,
      smallHeaderSize,
      calcActualSize,
    },
    meStyle,
  } = useInnerStyle();
  const bottomTabbarHeight = 0;
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState<IUserInfo>();
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });
  const [overHead, setOverHead] = useState(false);

  const opacity = useSharedValue(0);
  const showNoMenu = false;
  const topStyles = StyleSheet.create({
    headerImage: {
      height: smallHeaderSize,
      width: smallHeaderSize,
    },
  });
  const headerStyle = {
    height: 50,
    display: (overHead || opacity.value > 0 ? 'flex' : 'none') as
      | 'flex'
      | 'none',
    opacity: opacity,
    zIndex: 1,
  };

  const [vip, setVip] = useState<VipInfo>({
    level: 0,
    diff: 200,
    nextValue: 200,
  });

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const y = event.nativeEvent.contentOffset.y;
    // console.log('ev', y);
    setOverHead(y >= startTopUserHeight);
  };

  useEffect(() => {
    opacity.value = withTiming(overHead ? 1 : 0, {duration: 500});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overHead]);

  const {amountStyle} = useInnerStyle();

  const setDefaultVip = () => {
    postVipConfig().then(conf => {
      const currentConf = conf.find(c => c.level === 1);
      setVip({
        level: 0,
        diff: currentConf?.recharge || 200,
        nextValue: currentConf?.recharge || 200,
      });
    });
  };

  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      if (token) {
        refresh();
      } else {
        setUser(undefined);
        setDefaultVip();
        globalStore.userInfo = null;
        setMessageCountInfo({
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        });
      }
    });

    const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
      setMessageCountInfo(_countInfo);
    });
    return () => {
      sub.unsubscribe();
      msgSub.unsubscribe();
    };
  }, []);

  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      if (token) {
        globalStore.amountCheckOut.next({});
        refresh(false);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  useFocusEffect(handleFocusEffect);

  const refresh = async (loading: boolean = true) => {
    globalStore.refreshNotification.next();
    loading && globalStore.globalLoading.next(true);
    try {
      const results = await Promise.allSettled([postUserInfo(), postVipInfo()]);
      const [userInfo, vipInfo] = results;
      if (userInfo.status === 'fulfilled') {
        setUser(userInfo.value);
        globalStore.userInfo = userInfo.value;
      }
      if (vipInfo.status === 'fulfilled') {
        const {value: list} = vipInfo;
        for (let i = 0; i < list.length; i++) {
          if (list[i].diff > 0) {
            const current = list[i];
            setVip({
              level: current.level,
              diff: current.diff,
              nextValue: current.amount,
            });
            break;
          }
        }
      }
    } finally {
      globalStore.globalLoading.next(false);
      setRefreshing(false);
    }
  };
  const [refreshing, setRefreshing] = useState<boolean>(false);
  return (
    <FadeInView style={[theme.position.rel, meStyle.page]}>
      <Animated.View
        style={[headerStyle, theme.position.abs, theme.fill.fillW]}>
        <NativeTouchableOpacity
          activeOpacity={login ? 1 : 0.8}
          onPress={() => {
            if (!login) {
              goTo('Login');
            }
          }}
          style={[theme.fill.fill]}>
          <LinearGradient
            start={{x: 0, y: 1}}
            end={{x: 1, y: 0}}
            colors={theme.backgroundColor.meTopGradient}
            style={[
              theme.flex.flex1,
              theme.flex.row,
              theme.flex.centerByCol,
              theme.padding.lrl,
            ]}>
            <Image
              source={
                login ? user?.userAvatar || defaultHeaderImg : emptyHeaderImg
              }
              style={[topStyles.headerImage, theme.margin.rightl]}
            />
            <Text fontSize={theme.fontSize.m} blod style={[theme.font.white]}>
              {login ? user?.userName : i18n.t('me.user.login')}
            </Text>
          </LinearGradient>
        </NativeTouchableOpacity>
      </Animated.View>

      <ScrollView
        onScroll={onScroll}
        scrollEventThrottle={16}
        contentInsetAdjustmentBehavior="automatic"
        keyboardShouldPersistTaps="always"
        style={[
          {height: screenHeight - bottomTabbarHeight},
          theme.position.rel,
          theme.background.lightGrey,
        ]}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              if (!login) {
                goTo('Login');
                return;
              }
              setRefreshing(true);
              refresh(false);
            }}
          />
        }>
        <View style={[theme.position.abs, meStyle.topBackground]}>
          <LazyImage
            occupancy="#0000"
            imageUrl={topBackground}
            width={screenWidth}
            height={topBgHeight}
          />
        </View>
        <View
          style={[
            {
              paddingVertical: calcActualSize(16),
              paddingHorizontal: calcActualSize(12),
            },
          ]}>
          <MeUser user={user} login={login} />
          <View
            style={[
              theme.position.rel,
              theme.flex.col,
              theme.flex.end,
              amountStyle.outContainer,
            ]}>
            <MeVipCard vip={vip} login={login} />
            <MeAmount
              user={user}
              login={login}
              // onChangeTab={onChangeTab}
              onRefresh={() => {
                if (!login) {
                  goTo('Login');
                  return;
                }
                refresh();
              }}
            />
          </View>
          <MePromotions />
          <MeLists
            showNoMenu={showNoMenu}
            messageCountInfo={messageCountInfo}
            login={login}
            user={user}
          />
        </View>
        <View style={[meStyle.whiteArea]} />
      </ScrollView>
    </FadeInView>
  );
};

export default Me;
